@import "play-pause";

img, video {
    max-width: 100%;
}
.overlay {
    position: fixed;
    z-index: 200;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: hsla(0, 0%, 0%, 0.75);
    color: white;
    padding: var(--gap);
    overflow-y: scroll;
    font-family: $sans;
    a {
        color: white;
    }
}
.overlay > .close {
    position: fixed;
    top: var(--gap);
    right: var(--gap);
    background: none;
    font-size: 2rem;
    color: currentColor;
    border: none;
    cursor: pointer;
}
.overlay {
    display: grid;
    grid-gap: 0 var(--big-gap);
    grid-auto-rows: min-content;
    align-items: start;
    justify-items: center;
    height: 100%;
    > header {
        padding-top: 4rem;
        max-width: 60rem;
    }
    .artist-name {
        margin: 0;
        font-size: 3rem;
    }
    .specializes {
        font-style: italic;
    }
    .links {
        font-size: 85%;
        > a {
            display: inline-block;
            padding-right: 1rem;
        }
    }
    .portrait {
        max-height: 30rem;
        margin-left: auto;
        display: block;
    }
    .tabs {
        list-style: none;
        display: flex;
        flex-flow: row;
        margin: 0;
        padding: 0;
    }
    .tab {
        margin-top: 0.2rem;
        color: white;
        font-weighT: normal;
        text-transform: uppercase;
        padding: var(--small-gap);
        font-size: 140%;
        &.active {
            border-bottom: 2px solid currentColor;
        }
        &:hover,
        &:focus {
            text-decoration: none;
            border-bottom: 2px solid currentColor;
        }
    }
    .note {
        font-size: 85%;
        font-style: italic;
    }
    .info {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 6rem;
        > * {
            margin-bottom: $gap;
        }
        .title {
            text-transform: none;
            font-weight: bold;
            font-size: 300%;
        }
        &.bio {
            max-width: 60rem;
        }
        &.project {
            > .description {
                max-width: 50rem;
                .genre {
                    font-style: italic;
                }
            }
            > video.item, > img.item, > iframe.item, > .raw.item {
                margin: 5vh 0;
                max-width: 85vw;
                max-height: 90vh;
            }
            > .labelled.item {
                display: flex;
                flex-flow: row;
                align-items: center;
                width: 20rem;
            }
            > iframe.item {
                width: 100%;
                height: 90vh;
                border: none;
            }
            > article.item {
                max-width: 50rem;
            }
        }
    }
    .icon {
        width: 6rem;
    }
    .play-pause {
        width: 5rem;
        height: 5rem;
        padding: 1.5rem;
        margin: 0 0.5rem;
        cursor: pointer;
        & > .stop {
            font-size: 1.5rem;
        }
    }
    .ar-link {
        max-width: 30rem;
        display: flex;
        flex-flow: row;
        align-items: center;
        > span {
            padding: 0.5rem;
        }
        > .icon {
            width: 3rem;
        }
    }
}
@media (min-width: 60rem) {
    .overlay {
        > header, > .info.bio {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: var(--big-gap);
            align-items: start;
        }
        .artist-name {
            font-size: 4rem;
        }
        .links, .specializes, .artist-name {
            text-align: right;
        }
        .links a {
            padding-right: 0;
            padding-left: 1em;
        }
    }
}

.vel-modal {
    .vel-btns-wrapper {
        .btn__close {
            right: 24px;
        }
    }
}

#audio-container {
    position: fixed;
    z-index: 300;
    left: 0;
    bottom: 3rem;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    > audio {
        display: block;
        filter: drop-shadow(0 0 12px white);
        &:not(.active) {
            display: none;
        }
    }
}
