/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 13, 2021 */
@font-face {
    font-family: 'Visby CF';
    src: url('visbycf-extrabold.d5aaffa2.woff2') format('woff2'),
         url('visbycf-extrabold.57d112e6.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Visby CF';
    src: url('visbycf-heavy.38b70c14.woff2') format('woff2'),
         url('visbycf-heavy.eb6d2e97.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Visby CF';
    src: url('visbycf-light.cc0f9254.woff2') format('woff2'),
         url('visbycf-light.54bf0c35.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Visby CF';
    src: url('visbycf-lightoblique.6bb2d53d.woff2') format('woff2'),
         url('visbycf-lightoblique.4a7ea4df.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

/* caladea-latin-ext-400-normal*/
@font-face {
  font-family: 'Caladea';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('caladea-latin-ext-400-normal.b76a28a6.woff2') format('woff2'), url('caladea-all-400-normal.1dcde940.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* caladea-latin-400-normal*/
@font-face {
  font-family: 'Caladea';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('caladea-latin-400-normal.a7498202.woff2') format('woff2'), url('caladea-all-400-normal.1dcde940.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@charset "UTF-8";
.play-pause {
  border: 1px solid black;
  background: white;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  padding: 3rem;
}
.play-pause > .pause {
  display: none;
}
.play-pause.playing > .pause {
  display: block;
}
.play-pause.playing > .play {
  display: none;
}
img, video {
  max-width: 100%;
}
.overlay {
  position: fixed;
  z-index: 200;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: var(--gap);
  overflow-y: scroll;
  font-family: "Visby CF", sans-serif;
}
.overlay a {
  color: white;
}
.overlay > .close {
  position: fixed;
  top: var(--gap);
  right: var(--gap);
  background: none;
  font-size: 2rem;
  color: currentColor;
  border: none;
  cursor: pointer;
}
.overlay {
  display: grid;
  grid-gap: 0 var(--big-gap);
  grid-auto-rows: min-content;
  align-items: start;
  justify-items: center;
  height: 100%;
}
.overlay > header {
  padding-top: 4rem;
  max-width: 60rem;
}
.overlay .artist-name {
  margin: 0;
  font-size: 3rem;
}
.overlay .specializes {
  font-style: italic;
}
.overlay .links {
  font-size: 85%;
}
.overlay .links > a {
  display: inline-block;
  padding-right: 1rem;
}
.overlay .portrait {
  max-height: 30rem;
  margin-left: auto;
  display: block;
}
.overlay .tabs {
  list-style: none;
  display: flex;
  flex-flow: row;
  margin: 0;
  padding: 0;
}
.overlay .tab {
  margin-top: 0.2rem;
  color: white;
  font-weighT: normal;
  text-transform: uppercase;
  padding: var(--small-gap);
  font-size: 140%;
}
.overlay .tab.active {
  border-bottom: 2px solid currentColor;
}
.overlay .tab:hover, .overlay .tab:focus {
  text-decoration: none;
  border-bottom: 2px solid currentColor;
}
.overlay .note {
  font-size: 85%;
  font-style: italic;
}
.overlay .info {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 6rem;
}
.overlay .info > * {
  margin-bottom: 1rem;
}
.overlay .info .title {
  text-transform: none;
  font-weight: bold;
  font-size: 300%;
}
.overlay .info.bio {
  max-width: 60rem;
}
.overlay .info.project > .description {
  max-width: 50rem;
}
.overlay .info.project > .description .genre {
  font-style: italic;
}
.overlay .info.project > video.item, .overlay .info.project > img.item, .overlay .info.project > iframe.item, .overlay .info.project > .raw.item {
  margin: 5vh 0;
  max-width: 85vw;
  max-height: 90vh;
}
.overlay .info.project > .labelled.item {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 20rem;
}
.overlay .info.project > iframe.item {
  width: 100%;
  height: 90vh;
  border: none;
}
.overlay .info.project > article.item {
  max-width: 50rem;
}
.overlay .icon {
  width: 6rem;
}
.overlay .play-pause {
  width: 5rem;
  height: 5rem;
  padding: 1.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.overlay .play-pause > .stop {
  font-size: 1.5rem;
}
.overlay .ar-link {
  max-width: 30rem;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.overlay .ar-link > span {
  padding: 0.5rem;
}
.overlay .ar-link > .icon {
  width: 3rem;
}
@media (min-width: 60rem) {
  .overlay > header, .overlay > .info.bio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--big-gap);
    align-items: start;
  }
  .overlay .artist-name {
    font-size: 4rem;
  }
  .overlay .links, .overlay .specializes, .overlay .artist-name {
    text-align: right;
  }
  .overlay .links a {
    padding-right: 0;
    padding-left: 1em;
  }
}
.vel-modal .vel-btns-wrapper .btn__close {
  right: 24px;
}
#audio-container {
  position: fixed;
  z-index: 300;
  left: 0;
  bottom: 3rem;
  right: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
}
#audio-container > audio {
  display: block;
  filter: drop-shadow(0 0 12px white);
}
#audio-container > audio:not(.active) {
  display: none;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
@media (min-width: 720px) {
  :root {
    font-size: 115%;
  }

  p, ul {
    font-size: 115%;
  }
}
* {
  box-sizing: border-box;
}
:root {
  --gap: 1rem;
  --big-gap: 3rem;
  --small-gap: 0.25rem;
  --sans: Visby CF, sans-serif;
  --serif: Caladea, serif;
  --red: #ffa500;
}
body {
  font-family: var(--serif);
  margin: 0;
  padding: 0;
}
body.noscroll {
  overflow-y: hidden;
}
h1, h2 {
  font-family: var(--sans);
  text-transform: uppercase;
  font-weight: normal;
}
a {
  color: black;
  text-decoration: none;
  text-underline-offset: 0.1em;
}
a:hover, a:focus, summary:hover h2, summary:focus h2 {
  text-decoration: underline;
}
summary:focus {
  outline: none;
}
nav a {
  font-family: var(--sans);
  display: inline-block;
  font-weight: bold;
}
img {
  max-width: 100%;
}
@media (min-width: 60rem) {
  h1 {
    font-size: 3rem;
  }
}
p {
  text-align: left;
  hyphens: auto;
}
p > a {
  text-decoration: underline;
}
ul.flat, .page-type-info ul.artist-list, ol.flat, .page-type-info ol.artist-list {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}
ul li {
  margin: var(--small-gap);
}
/* === components === */
.button-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.blob-button {
  font-family: var(--sans);
  display: block;
  position: relative;
  margin: 5rem;
  text-transform: uppercase;
  text-align: center;
  min-width: 15rem;
  font-weight: bold;
  transform: scale(1);
  transition: all 1s;
}
@media (hover: hover) {
  .blob-button {
    filter: blur(3px);
  }
}
.blob-button:hover, .blob-button:focus {
  transform: scale(1.1);
  filter: none;
}
.blob-button:hover, .blob-button:focus {
  text-decoration: none;
}
.blob-button:before {
  display: block;
  content: url("anabasis-button.5ae114e3.svg");
  position: absolute;
  z-index: -5;
  top: -3rem;
  left: 50%;
  margin-left: -8rem;
  width: 100%;
  animation: wobble infinite 37s linear;
  animation-fill-mode: both;
}
.blob-button:nth-child(1):before {
  animation-delay: -18s;
}
#apply .blob-button {
  font-size: 300%;
}
#apply .blob-button:before {
  animation-delay: -30s;
}
/* === cover === */
#cover {
  margin: 0 auto;
  min-height: 100vh;
  grid-gap: 1rem;
  max-width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}
#nav-top {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 60vh;
}
#nav-top > a {
  padding: var(--small-gap) 0;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  position: sticky;
  top: var(--gap);
  transition: all 1s;
}
#nav-top > a:hover, #nav-top > a:focus {
  transform: rotate(-7deg) scale(1.1);
}
#nav-top > a > img {
  max-width: 8rem;
}
#nav-bottom {
  position: fixed;
  bottom: var(--gap);
  right: var(--gap);
  z-index: 10;
}
#nav-bottom ul {
  list-style: none;
}
#nav-bottom ul li {
  display: inline;
}
#osd {
  position: fixed;
  bottom: 0;
  left: 1.5rem;
  padding: var(--gap);
  z-index: 5;
  color: #0B7C66;
  font-family: monospace;
  font-size: 1.1rem;
  font-weight: bold;
}
#scene {
  cursor: grab;
}
/* === page === */
#page {
  max-width: 45rem;
  padding: var(--gap);
  background: white;
  margin: 0 2rem;
  margin-top: 100vh;
  z-index: 10;
  position: relative;
}
@media (min-width: 65rem) {
  #page {
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.heading-blob {
  display: block;
  margin: 0 auto;
}
#description > h1 {
  text-align: center;
}
.subheading {
  font-weight: bold;
}
.person > .bio {
  margin: var(--small-gap);
  margin-bottom: var(--gap);
  font-size: 88%;
}
.person > .bio > summary {
  font-style: italic;
}
.person > .bio > p {
  font-size: 100%;
  margin: var(--small-gap) 0;
  color: #333333;
}
/* === details === */
#details {
  align-items: flex-start;
}
details {
  max-width: 40rem;
  margin-left: 1rem;
}
summary {
  cursor: pointer;
}
summary {
  list-style: none;
}
summary::-webkit-details-marker {
  display: none;
}
details > summary > h2 {
  margin-left: 1.5rem;
  position: relative;
}
details > summary > h2:before {
  position: absolute;
  content: "◦";
  display: block;
  width: 1rem;
  margin-left: -1.5rem;
  opacity: 0.7;
  transition: all 1s;
  transform: scale(0.5);
}
details[open] > summary > h2:before {
  opacity: 1;
  transform: scale(1);
}
details > p, ul {
  margin: 0;
  padding: 0;
  margin-left: 2rem;
}
/* === intro === */
#intro {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 400px;
  transform: translateX(-350px);
  transition: transform 0.75s;
  background: url("intro-bg.c390b5c5.svg");
  background-position: right center;
  background-repeat: none;
  padding-right: 100px;
  padding-left: var(--gap);
  padding-bottom: var(--gap);
  display: flex;
  flex-flow: column;
  z-index: 100;
}
#intro > .inner {
  font-size: 80%;
  overflow-y: auto;
  height: 100%;
}
#intro.active {
  transform: translateX(0);
}
#intro .activate {
  border: none;
  position: absolute;
  bottom: 35%;
  height: 10%;
  right: var(--gap);
  background: none;
  font-size: 150%;
}
#intro .close {
  background: none;
  border: 1px solid black;
  border-radius: 1em;
  margin: 0.5em;
  padding: 0.5em 1.5em;
}
@media (hover: hover) {
  #intro:hover {
    transform: translateX(0);
  }
  #intro .close, #intro .activate {
    display: none;
  }
}
/* === animations === */
@keyframes wobble {
  to {
    transform: rotate(360deg);
  }
}
@keyframes compass {
  25% {
    transform: rotate(12deg);
  }
  38% {
    transform: rotate(-4deg);
  }
  64% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(-14deg);
  }
}
.page-type-info {
  background: url("grid-bg.63a274bb.svg");
  background-size: 100%;
  background-repeat: repeat-y;
}
@media (max-width: 60rem) {
  .page-type-info {
    padding: 1rem;
  }
}
.page-type-info #about, .page-type-info #legal, .page-type-info #contact {
  display: grid;
}
.page-type-info #about h1, .page-type-info #legal h1, .page-type-info #contact h1 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  max-width: 10ch;
}
@media (min-width: 60rem) {
  .page-type-info #about h1, .page-type-info #legal h1, .page-type-info #contact h1 {
    font-size: 8rem;
  }
}
@media (min-width: 60rem) {
  .page-type-info #about, .page-type-info #legal, .page-type-info #contact {
    grid-template-columns: 3fr 6fr 3fr;
  }
  .page-type-info #about .logos, .page-type-info #legal .logos, .page-type-info #contact .logos {
    grid-column: 2;
  }
}
.page-type-info #legal > .inner, .page-type-info #contact > .inner {
  margin: 6rem 0;
}
.page-type-info #legal > .inner h3, .page-type-info #contact > .inner h3 {
  font-family: "Visby CF", sans-serif;
  font-weight: normal;
}
.page-type-info > .to-space {
  position: fixed;
  z-index: 1000;
  top: 5rem;
  right: 1rem;
  width: 8rem;
}
.page-type-info > .to-space .blob-button {
  margin: 1rem;
}
@media (max-width: 60rem) {
  .page-type-info > .to-space .blob-button {
    transform: scale(0.7);
    font-size: 0.7rem;
    filter: drop-shadow(2px 2px 0 white);
  }
}
.page-type-info > .page-nav {
  position: sticky;
}
.page-type-info > .page-nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.page-type-info > .page-nav a {
  text-transform: lowercase;
  color: #666;
  font-weight: normal;
  line-height: 1.6rem;
}
.page-type-info > .page-nav a:hover, .page-type-info > .page-nav a:focus {
  color: black;
  text-decoration: none;
}
@media (min-width: 60rem) {
  .page-type-info > .page-nav a {
    font-size: 1.4rem;
  }
  .page-type-info > .page-nav a:hover, .page-type-info > .page-nav a:focus {
    font-weight: bold;
  }
}
@media (max-width: 60rem) {
  .page-type-info > .page-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 1000;
  }
  .page-type-info > .page-nav > ul {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
  }
  .page-type-info > .page-nav > ul a {
    padding: 1rem 0;
  }
}
@media (min-width: 60rem) {
  .page-type-info > .page-nav {
    top: 1rem;
    left: 8.3333333333%;
    width: 16.6666666667%;
  }
}
.page-type-info > section > h2, .page-type-info > section > h3 {
  font-family: var(--sans);
  text-transform: lowercase;
  font-weight: bold;
  color: #aeaeae;
}
.page-type-info > section > h2 {
  font-size: 3rem;
}
.page-type-info > section > h3 {
  font-size: 2rem;
  transform: rotate(-90deg) translate(0, 0.75rem);
  display: block;
  width: 8.3333333333%;
  transform-origin: 100% 100%;
  padding-bottom: 0;
  color: #999;
}
@media (max-width: 60rem) {
  .page-type-info > section > h3 {
    font-size: 1.4rem;
    font-weight: normal;
    color: black;
  }
}
.page-type-info > section > h2 ~ h3 {
  margin-top: 6rem;
  margin-bottom: 4rem;
}
@media (min-width: 60rem) {
  .page-type-info #about > .inner {
    grid-column: 2;
  }
}
.page-type-info p {
  font-family: var(--sans);
}
.page-type-info .person-list {
  display: grid;
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: var(--sans);
}
.page-type-info .person-list > li .name {
  font-family: var(--sans);
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  padding: 0;
  margin: 0.25em 0;
}
@media (min-width: 60rem) {
  .page-type-info .person-list > li .name {
    font-size: 8rem;
  }
}
.page-type-info .person-list > li .role {
  margin: 0.25em 0;
  font-weight: normal;
  font-size: 150%;
}
.page-type-info .person-list > li .photo {
  position: relative;
  top: 0;
  left: 15%;
  max-width: 80%;
  z-index: -1;
}
.page-type-info .person-list > li > .role + p {
  margin-top: 0;
}
.page-type-info .person-list > li > p {
  line-height: 1.7;
  font-size: 85%;
}
@media (min-width: 60rem) {
  .page-type-info .person-list {
    grid-template-columns: 3fr 2fr 2fr 2fr 3fr;
  }
  .page-type-info .person-list > li {
    grid-column: 2/span 2;
  }
  .page-type-info .person-list > li > .name {
    margin-right: -15vw;
  }
  .page-type-info .person-list > li:nth-child(even) {
    grid-column: 3/span 2;
  }
}
.page-type-info .person-list > [data-person=ferrando] .photo {
  top: 5rem;
  left: 15%;
}
@media (min-width: 60rem) {
  .page-type-info .person-list > [data-person=ferrando] .photo {
    top: 12rem;
    left: 12rem;
  }
}
.page-type-info .person-list > [data-person=oliver] .photo {
  top: 3rem;
}
@media (min-width: 80rem) {
  .page-type-info .person-list > [data-person=oliver] .photo {
    top: 6rem;
    left: 16rem;
  }
}
.page-type-info .person-list > [data-person=rychlo] .photo {
  left: 0;
}
.page-type-info .person-list > [data-person=sars] .photo {
  top: 10%;
  left: 34%;
}
.page-type-info .person-list > [data-person=tobias] .photo {
  left: 0;
}
.page-type-info #team > .person-list .photo {
  left: 0;
}
.page-type-info #team > .person-list [data-person=dacz] .photo {
  left: 10%;
  top: 3rem;
}
@media (min-width: 60rem) {
  .page-type-info #team > .person-list [data-person=dacz] .photo {
    left: 20%;
    top: 8rem;
  }
}
@media (min-width: 60rem) {
  .page-type-info #team > .person-list li:nth-child(even) .photo {
    left: 40%;
  }
}
.page-type-info .artist-list {
  text-align: right;
  font-size: 1.65rem;
  line-height: 0.8;
  font-family: var(--sans);
  font-weight: bold;
}
@media (min-width: 48ch) {
  .page-type-info .artist-list {
    font-size: 2.8rem;
  }
}
@media (min-width: 65rem) {
  .page-type-info .artist-list {
    font-size: 8rem;
  }
}
.page-type-info .artist-list a {
  color: #3c3c3b;
}
.page-type-info .artist-list a:hover, .page-type-info .artist-list a:focus {
  color: #0B7C66;
  text-decoration: none;
}
/* === logos === */
.logos {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 36rem) {
  .overlap {
    margin-bottom: -12px;
  }

  .pad {
    margin-top: 8px;
  }
}
.logos img {
  max-width: 8rem;
}
.logo-row {
  display: flex;
  flex-flow: row;
}
@media (min-width: 36rem) {
  .logos {
    display: flex;
    flex-flow: row;
    max-width: none;
    justify-content: center;
    align-items: center;
  }

  .logos a {
    padding: var(--small-gap);
  }

  .logos img {
    max-height: 4rem;
    max-width: auto;
  }
}
.jNQiPH {
  bottom: 50px !important;
}
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/*# sourceMappingURL=index.fca1aa4c.css.map */
