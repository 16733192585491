@use "sass:math";

@mixin heading-sizes {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;
    @media (min-width: $breakpoint-1) {
        font-size: 8rem;
    }
}

.page-type-info {
    background: url("../assets/grid-bg.svg");
    background-size: 100%;
    background-repeat: repeat-y;

    @media (max-width: $breakpoint-1) {
        padding: $gap;
    }

    #about, #legal, #contact {
        display: grid;
        h1 {
            @include heading-sizes;
            max-width: 10ch;
        }
        @media (min-width: $breakpoint-1) {
            grid-template-columns: 3fr 6fr 3fr;
            .logos {
                grid-column: 2;
            }
        }
    }
    #legal, #contact {
        > .inner {
            h3 {
                font-family: $sans;
                font-weight: normal;
            }
            margin: 6rem 0;
        }
    }
    > .to-space {
        position: fixed;
        z-index: 1000;
        top: 5rem;
        right: $gap;
        width: 8rem;
        .blob-button {
            margin: $gap;
            @media (max-width: $breakpoint-1) {
                transform: scale(0.7);
                font-size: 0.7rem;
                filter: drop-shadow(2px 2px 0 white);
            }
        }
    }
    
    > .page-nav {
        position: sticky;
        > ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        a {
            text-transform: lowercase;
            color: #666;
            font-weight: normal;
            line-height: 1.6rem;
            &:hover, &:focus {
                color: black;
                text-decoration: none;
            }
            @media (min-width: $breakpoint-1) {
                font-size: 1.4rem;
                &:hover, &:focus {
                    font-weight: bold;
                }
            }
        }
        @media (max-width: $breakpoint-1) {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: white;
            z-index: 1000;
            > ul {
                display: flex;
                flex-flow: row;
                justify-content: space-around;
                a {
                    padding: $gap 0;
                }
            }
        }
        @media (min-width: $breakpoint-1) {
            top: $gap;
            left: math.div(100%, 12);
            width: math.div(100%, 6);
        }
    }

    > section {
        > h2, > h3 {
            font-family: var(--sans);
            text-transform: lowercase;
            font-weight: bold;
            color: $middle-gray;
        }
        > h2 {
            font-size: 3rem;
        }
        > h3 {
            font-size: 2rem;
            transform: rotate(-90deg) translate(0, 0.75rem);
            display: block;
            width: calc(100% / 12);
            transform-origin: 100% 100%;
            padding-bottom: 0;
            color: #999;
            @media (max-width: $breakpoint-1) {
                font-size: 1.4rem;
                font-weight: normal;
                color: black;
            }
        }
        > h2 ~ h3 {
            margin-top: 6rem;
            margin-bottom: 4rem;
        }
    }

    @media (min-width: $breakpoint-1) {
        #about > .inner {
            grid-column: 2;
        }
    }

    p {
        font-family: var(--sans);
    }

    .person-list {
        display: grid;
        list-style: none;
        padding: 0;
        margin: 0;
        font-family: var(--sans);
        > li {
            .name {
                font-family: var(--sans);
                @include heading-sizes;
                padding: 0;
                margin: 0.25em 0;
            }
            .role {
                margin: 0.25em 0;
                font-weight: normal;
                font-size: 150%;
            }
            .photo {
                position: relative;
                top: 0;
                left: 15%;
                max-width: 80%;
                z-index: -1;
            }
            > .role + p {
                margin-top: 0;
            }
            > p {
                line-height: 1.7;
                font-size: 85%;
            }
        }

        @media (min-width: $breakpoint-1) {
            grid-template-columns: 3fr 2fr 2fr 2fr 3fr;
            > li {
                grid-column: 2 / span 2;
                > .name {
                    margin-right: -15vw;
                }
            }
            > li:nth-child(even) {
                grid-column: 3 / span 2;
            }
            > li:nth-child(odd) {
            }
        }
        > [data-person=ferrando] {
            .photo {
                top: 5rem;
                left: 15%;
                @media (min-width: $breakpoint-1) {
                    top: 12rem;
                    left: 12rem;
                }
            }
        }
        > [data-person=oliver] {
            .photo {
                top: 3rem;
                @media (min-width: $breakpoint-2) {
                    top: 6rem;
                    left: 16rem;
                }
            }
        }
        > [data-person=rychlo] {
            .photo {
                left: 0;
            }
        }
        > [data-person=sars] {
            .photo {
                top: 10%;
                left: 34%;
            }
        }
        > [data-person=tobias] {
            .photo {
                left: 0;
            }
        }
    }
    #team > .person-list {
        .photo {
            left: 0;
        }
        [data-person=dacz] {
            .photo {
                left: 10%;
                top: 3rem;
                @media (min-width: $breakpoint-1) {
                    left: 20%;
                    top: 8rem;
                }
            }
        }

        @media (min-width: $breakpoint-1) {
            li:nth-child(even) {
                .photo {
                    left: 40%;
                }
            }
        }
    }
    .artist-list {
        @extend .flat;
        text-align: right;
        font-size: 1.65rem;
        line-height: 0.8;
        font-family: var(--sans);
        font-weight: bold;
        @media (min-width: 48ch) {
            font-size: 2.8rem;
        }
        @media (min-width: 65rem) {
            font-size: 8rem;
        }
        a {
            color: $dark-gray;
            &:hover, &:focus {
                color: $sea;
                text-decoration: none;
            }
        }
    }
}
