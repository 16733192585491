@import "../assets/fonts/fonts.css";
@import "~/node_modules/@fontsource/caladea/400.css";
@import "constants";
@import "overlay";

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}
@media (min-width: 720px) {
    :root {
        font-size: 115%;
    }
    p, ul {
        font-size: 115%;
    }
}
* {
    box-sizing: border-box;
}
:root {
    --gap: 1rem;
    --big-gap: 3rem;
    --small-gap: 0.25rem;
    --sans: #{$sans};
    --serif: #{$serif};

    --red: #ffa500;
}
body {
    font-family: var(--serif);
    margin: 0;
    padding: 0;
    &.noscroll {
        overflow-y: hidden;
    }
}
h1, h2 {
    font-family: var(--sans);
    text-transform: uppercase;
    font-weight: normal;
}
a {
    color: black;
    text-decoration: none;
    text-underline-offset: 0.1em;
}
a:hover, a:focus, summary:hover h2, summary:focus h2 {
    text-decoration: underline;
}
summary:focus {
    outline: none;
}
nav a {
    font-family: var(--sans);
    display: inline-block;
    font-weight: bold;
}
img {
    max-width: 100%;
}
@media (min-width: 60rem) {
    h1 {
        font-size: 3rem;
    }
}
p {
    text-align: left;
    hyphens: auto;
}
p > a {
    text-decoration: underline;
}
ul.flat, ol.flat {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
}
ul li {
    margin: var(--small-gap);
}


/* === components === */
@mixin blur-button {
    transform: scale(1);
    transition: all 1s;
    @media (hover: hover) {
        & {
            filter: blur(3px);
        }
    }
    &:hover, &:focus {
        transform: scale(1.1);
        filter: none;
    }
}

.button-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}
.blob-button {
    font-family: var(--sans);
    display: block;
    position: relative;
    margin: 5rem;
    text-transform: uppercase;
    text-align: center;
    min-width: 15rem;
    font-weight: bold;
    @include blur-button;
}
.blob-button:hover, .blob-button:focus {
    text-decoration: none;
}
.blob-button:before {
    display: block;
    content: url("../assets/anabasis-button.svg");
    position: absolute;
    z-index: -5;
    top: -3rem;
    left: 50%;
    margin-left: -8rem;
    width: 100%;
    animation: wobble infinite 37s linear;
    animation-fill-mode: both;
}

.blob-button:nth-child(1):before {
    animation-delay: -18s;
}
.blob-button:nth-child(2):before {
}
#apply .blob-button {
    font-size: 300%;
}
#apply .blob-button:before {
    animation-delay: -30s;
}

/* === cover === */
#cover {
    margin: 0 auto;
    min-height: 100vh;
    grid-gap: 1rem;
    max-width: 100vw;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
}
#nav-top {
    position: absolute;
    top: $gap;
    right: $gap;
    height: 60vh;
}
#nav-top > a {
    padding: var(--small-gap) 0;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    position: sticky;
    top: var(--gap);
    transition: all 1s;
    &:hover, &:focus {
        transform: rotate(-7deg) scale(1.1);
    }
    > img {
        max-width: 8rem;
    }
}
#nav-bottom {
    position: fixed;
    bottom: var(--gap);
    right: var(--gap);
    z-index: 10;
    ul {
        list-style: none;
        li {
            display: inline;
        }
    }
}
#osd {
    position: fixed;
    bottom: 0;
    left: 1.5rem;
    padding: var(--gap);
    z-index: 5;
    color: $sea;
    font-family: monospace;
    font-size: 1.1rem;
    font-weight: bold;
}
#scene {
    cursor: grab;
}

/* === page === */
#page {
    max-width: 45rem;
    padding: var(--gap);
    background: white;
    margin: 0 2rem;
    margin-top: 100vh;
    z-index: 10;
    position: relative;
}
@media (min-width: 65rem) {
    #page {
        padding: 2rem;
        margin-left: auto;
        margin-right: auto;
    }
}
.heading-blob {
    display: block;
    margin: 0 auto;
}
#description > h1 {
    text-align: center;
}
.subheading {
    font-weight: bold;
}
.person > .bio {
  margin: var(--small-gap);
  margin-bottom: var(--gap);
  font-size: 88%;
}
.person > .bio > summary {
  font-style: italic;
}
.person > .bio > p {
  font-size: 100%;
  margin: var(--small-gap) 0;
  color: hsl(0, 0%, 20%);
}

/* === details === */
#details {
    align-items: flex-start;
}
details {
    max-width: 40rem;
    margin-left: 1rem;
}
summary {
    cursor: pointer;
}
summary {
    list-style: none;
}
summary::-webkit-details-marker {
    display: none;
}
details > summary > h2 {
    margin-left: 1.5rem;
    position: relative;
}
details > summary > h2:before {
    position: absolute;
    content: "◦";
    display: block;
    width: 1rem;
    margin-left: -1.5rem;
    opacity: 0.7;
    transition: all 1s;
    transform: scale(0.5);
}
details[open] > summary > h2:before {
    opacity: 1;
    transform: scale(1);
}
details > p, ul {
    margin: 0;
    padding: 0;
    margin-left: 2rem;
}

/* === intro === */
#intro {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 400px;
    transform: translateX(-350px);
    transition: transform $dur-1;
    background: url("../assets/intro-bg.svg");
    background-position: right center;
    background-repeat: none;
    padding-right: 100px;
    padding-left: var(--gap);
    padding-bottom: var(--gap);
    display: flex;
    flex-flow: column;
    z-index: 100;
    > .inner {
        font-size: 80%;
        overflow-y: auto;
        height: 100%;
    }
    &.active {
        transform: translateX(0);
    }
    .activate {
        border: none;
        position: absolute;
        bottom: 35%;
        height: 10%;
        right: var(--gap);
        background: none;
        font-size: 150%;
    }
    .close {
        background: none;
        border: 1px solid black;
        border-radius: 1em;
        margin: 0.5em;
        padding: 0.5em 1.5em;
    }
    @media (hover: hover) {
        &:hover {
            transform: translateX(0);
        }
        .close, .activate {
            display: none;
        }
    }
}

/* === animations === */
@keyframes wobble {
    to {
        transform: rotate(360deg);
    }
}
@keyframes compass {
    25% {
        transform: rotate(12deg);
    }
    38% {
        transform: rotate(-4deg);
    }
    64% {
        transform: rotate(-10deg);
    }
    75% {
        transform: rotate(-14deg);
    }
}

@import "info";
@import "person-list";
@import "logos";

.jNQiPH {
    
    bottom: 50px !important;
    
}
