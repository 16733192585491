/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 13, 2021 */
@font-face {
    font-family: 'Visby CF';
    src: url('visbycf-extrabold.woff2') format('woff2'),
         url('visbycf-extrabold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Visby CF';
    src: url('visbycf-heavy.woff2') format('woff2'),
         url('visbycf-heavy.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Visby CF';
    src: url('visbycf-light.woff2') format('woff2'),
         url('visbycf-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Visby CF';
    src: url('visbycf-lightoblique.woff2') format('woff2'),
         url('visbycf-lightoblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
