/* === logos === */
.logos {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
@media (max-width: 36rem) {
    .overlap {
        margin-bottom: -12px;
    }
    .pad {
        margin-top: 8px;
    }
}
.logos img {
    max-width: 8rem;
}
.logo-row {
    display: flex;
    flex-flow: row;
}
@media (min-width: 36rem) {
    .logos {
        display: flex;
        flex-flow: row;
        max-width: none;
        justify-content: center;
        align-items: center;
    }
    .logos a {
        padding: var(--small-gap);
    }
    .logos img {
        max-height: 4rem;
        max-width: auto;
    }
}
