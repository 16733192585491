.play-pause {
    border: 1px solid black;
    background: white;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    padding: 3rem;
    > .pause {
        display: none;
    }
    &.playing {
        > .pause {
            display: block;
        }
        > .play {
            display: none;
        }
    }
}
